import { EyeIcon, PencilAltIcon, XIcon } from "@heroicons/react/solid";
import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getUserLeaveData } from "../../api";
import Layout from "../../components/layout/Layout";
import spinner from "../../assets/images/spinner.gif";
import ZoomImage from "../../components/ZoomImage";
import Cookies from "universal-cookie";
import { ROLE } from "../../config";
import LeavesTab from "./LeavesTab";
import NewLeaveDetail from "./NewLeaveDetail";
import Pagination from "../../components/pagination";
import useGet from "../../hooks/read/useGet";
import DatePicker from "react-datepicker";
import LeaveManagement from "./LeaveManagement";
const cookies = new Cookies();

function LeaveDetail() {
  const [pageNo, setPageNo] = useState(0);
  const [year, setYear] = useState(new Date());
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  let initialHolidayFilterData = {
    limit: 10,

    page_no: 0,
    user_id: id,
    // month_and_year: searchParams.get("month_and_year")
    //   ? new Date(searchParams.get("month_and_year"))
    //   : new Date()
    month_year: year.getFullYear(),
  };
  const [filterData, setFilterData] = useState(initialHolidayFilterData);
  let initialHolidayData = {
    limit: 10,
    user_id: id,
    // search_query: searchParams.get("search_query") || "",
    page_no: 0,
    // month_and_year: searchParams.get("month_and_year"),
    month_year: year.getFullYear(),
  };
  const {
    list: holidayList,
    // paramsObject,
    setGetListParams,
  } = useGet({
    url: "getUserLeaveCount",
    method: "get",
    initialData: initialHolidayData,
  });
  const handleSetYear = (value) => {
    let data = {
      ...paramsObject,
      user_id: id,
      month_year: value.getFullYear(),
    };
    // console.log(value);

    let dataForSearchParams = {
      ...paramsObject,
      user_id: id,
      month_year: value.getFullYear(),
    };
    getLeaveData(
      id,
      paramsObject.page_no,
      paramsObject.limit,
      value.getFullYear()
    );
    setYear(value);
    setGetListParams(data);
    setSearchParams(dataForSearchParams);
    setFilterData(dataForSearchParams);
    // console.log(dataForSearchParams);
  };

  const [role, setRole] = useState("");
  const [activeTab, setActiveTab] = useState("leaveDetails");
  useEffect(() => {
    setRole(cookies.get(ROLE));
  }, []);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [leaveInfo, setLeaveInfo] = useState("");
  const [leaveData, setLeaveData] = useState([]);
  const [userId, setUserId] = useState("");
  const [leaveDocument, setLeaveDocument] = useState([]);
  const [singleLeaveData, setSingleLeaveData] = useState({});
  const [isOpenDocumentModal, setIsOpenDocumentModal] = useState(false);
  const [paramsObject, setParamsObject] = useState({
    page_no: 0,
    limit: 10,
    month_year: year.getFullYear(),
  });
  const [userLeaveCount, setUserLeaveCount] = useState(null);

  useEffect(() => {
    handleGetUserLeaveData();
  }, []);
  useEffect(() => {
    setRole(cookies.get("ROLE"));
    handleGetUserLeaveData();
  }, [paramsObject]); // Refetch when paramsObject changes
  const getLeaveData = (id, page_no, limit, month_year) => {
    getUserLeaveData(id, page_no, limit, month_year)
      .then((res) => {
        if (res.status === 200) {
          setLeaveData(res.data.data);
          setLeaveInfo(res.data);
          // setUserLeaveCount
          setIsLoading(false);
        }
      })
      .catch((err) => {
        alert("something went wrong please refresh");
        setIsLoading(false);
      });
  };
  const handleGetUserLeaveData = () => {
    setIsLoading(true);
    getLeaveData(
      id,
      paramsObject.page_no,
      paramsObject.limit,
      paramsObject.month_year
    );
  };
  const handleFetchUserDocuments = (id) => {
    setUserId(id);
    setSingleLeaveData(leaveData.find((data) => data.id === id));
    setIsOpenDocumentModal(true);
  };
  const { username } = useParams();
  // console.log(id);
  const handlePageClick = (page) => {
    // Update paramsObject when page changes
    getLeaveData(
      id,
      page.selected,
      paramsObject.limit,
      paramsObject.month_year
    );
    setPageNo(page.selected);
    // setParamsObject((prev) => ({
    //   ...prev,
    //   page_no: pageNo,
    // }));
  };

  // console.log(holidayList?.data?.data);

  return (
    <>
      <div className=" pb-20">
        {username ? (
          <p className="text-2xl font-semibold ml-4  text-primary">
            {username}
          </p>
        ) : (
          " "
        )}
        <div className="flex flex-col justify-center">
          <div className=" ">
            <label className=" roboto p-2 label">Select Year</label>

            <DatePicker
              name="month_year"
              autoComplete="false"
              className="input w-[300px] ml-4"
              // selected={filterData.month_year}
              selected={year}
              dateFormat="yyyy"
              showYearPicker
              onChange={handleSetYear}
            />
          </div>
          <div className="flex gap-20 ml-4 items-center">
            <div className="bg-primary text-white font-semibold p-5 rounded-xl my-5">
              <span className="mr-2">Applicable Leave:</span>
              {holidayList?.data?.data?.applicable}
            </div>
            <div className="bg-primary text-white font-semibold p-5 rounded-xl my-5">
              <span className="mr-2"> Remaining Leave:</span>
              {holidayList?.data?.data?.remaining}
            </div>
          </div>
        </div>
        <div className="container px-4">
          <div className="tabs">
            {/* Tab Buttons */}
            <div className="flex border-b mb-4">
              <button
                className={`px-4 py-2 focus:outline-none ${
                  activeTab === "leaveDetails"
                    ? "border-b-2 border-primary text-primary"
                    : ""
                }`}
                onClick={() => setActiveTab("leaveDetails")}
              >
                Leave Application
              </button>
              <button
                className={`px-4 py-2 focus:outline-none ${
                  activeTab === "newLeave"
                    ? "border-b-2 border-primary text-primary"
                    : ""
                }`}
                onClick={() => setActiveTab("newLeave")}
              >
                Leave Utilised
              </button>
              <button
                className={`px-4 py-2 focus:outline-none ${
                  activeTab === "leavemanagement"
                    ? "border-b-2 border-primary text-primary"
                    : ""
                }`}
                onClick={() => setActiveTab("leavemanagement")}
              >
                Leave Management
              </button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="w-12 h-12 mx-auto mt-10">
            <img src={spinner} alt="spinner" className="w-full h-full" />
          </div>
        ) : (
          <div className="container px-4">
            <div className="grid grid-cols-4 gap-x-4 mt-4">
              {leaveInfo.pending_pl_leave && (
                <div className="bg-orange-700 text-white text-center py-5 rounded">
                  <h1 className=" text-2xl font-semibold">{`${leaveInfo.pending_pl_leave}/${leaveInfo.applicable_pl}`}</h1>
                  <h3 className="text-lg font-semibold">PL Available</h3>
                </div>
              )}

              {leaveInfo.pending_cl_leave && (
                <div className="bg-orange-700 text-white text-center py-5 rounded">
                  <h1 className=" text-2xl font-semibold">{`${leaveInfo.pending_cl_leave}/${leaveInfo.applicable_cl}`}</h1>
                  <h3 className="text-lg font-semibold">CL Available</h3>
                </div>
              )}

              {leaveInfo.pending_sl_leave && (
                <div className="bg-orange-700 text-white text-center py-5 rounded">
                  <h1 className=" text-2xl font-semibold">{`${leaveInfo.pending_sl_leave}/${leaveInfo.applicable_sl}`}</h1>
                  <h3 className="text-lg font-semibold">SL Available</h3>
                </div>
              )}

              {leaveInfo.pending_ol_leave && (
                <div className="bg-orange-700 text-white text-center py-5 rounded">
                  <h1 className=" text-2xl font-semibold">{`${leaveInfo.pending_ol_leave}/${leaveInfo.applicable_ol}`}</h1>
                  <h3 className="text-lg font-semibold">OL Available</h3>
                </div>
              )}

              {leaveInfo.pending_dl_leave && (
                <div className="bg-orange-700 text-white text-center py-5 rounded">
                  <h1 className=" text-2xl font-semibold">{`${leaveInfo.pending_dl_leave}/${leaveInfo.applicable_dl}`}</h1>
                  <h3 className="text-lg font-semibold">DL Available</h3>
                </div>
              )}
            </div>
            {activeTab === "leaveDetails" &&
              (leaveData.length <= 0 ? (
                <div className="text-center">
                  <p className=" text-gray-700 text-3xl">No Leaves To Show</p>
                </div>
              ) : (
                <div class="max-w-full overflow-x-auto mt-8">
                  <table class="table-auto w-full">
                    <thead>
                      <tr class=" bg-[color:var(--color1)] text-center">
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">
                          Sr.no
                        </th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                          Dates
                        </th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">
                          Reason
                        </th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">
                          Leave Type
                        </th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">
                          Status
                        </th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                          Document
                        </th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {leaveData?.map((data, idx) => {
                        const { id } = data;
                        return (
                          <tr key={idx}>
                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                              {(pageNo * paramsObject.limit) + idx+1}
                            </td>
                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                              <small className="px-2 py-1 rounded bg-gray-500 text-white block">
                                From :{" "}
                                <Moment unix format="DD/MM/YYYY">
                                  {data.from_date}
                                </Moment>
                              </small>
                              {data.to_date !== "0" && (
                                <small className="px-2 py-1 rounded bg-gray-500 text-white block mt-2">
                                  To :{" "}
                                  <Moment unix format="DD/MM/YYYY">
                                    {data.to_date}
                                  </Moment>
                                </small>
                              )}
                            </td>
                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                              {data.reason}
                            </td>
                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                              {data.leave_type}
                            </td>
                            <td className=" text-center border-b bg-[#F3F6FF]">
                              {data.leave_status === "1" ? (
                                <span className="bg-green-700 text-green-200 px-2 py-1 rounded-full text-xs">
                                  Approved
                                </span>
                              ) : data.leave_status === "2" ? (
                                <span className="bg-yellow-600 text-yellow-200 px-2 py-1 rounded-full text-xs">
                                  Pending
                                </span>
                              ) : (
                                <span className="bg-red-700 text-red-200 px-2 py-1 rounded-full text-xs">
                                  Rejected
                                </span>
                              )}
                            </td>
                            <td class="text-center select-none text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                              {data.file_list ? (
                                <div className="flex items-center justify-center w-full">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleFetchUserDocuments(data.id)
                                    }
                                    className="bg-[color:var(--color1)] text-white rounded px-4 py-2 flex items-center space-x-2 justify-center"
                                  >
                                    <EyeIcon className="w-6 h-6" />{" "}
                                    <span>View</span>
                                  </button>
                                </div>
                              ) : (
                                "---"
                              )}
                            </td>
                            <td class="text-center  items-center justify-center w-full h-full text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                              <div className="flex items-center justify-center space-x-3">
                                {data.leave_edited && (
                                  <Link
                                    to={{
                                      pathname: `/leaves/leave-edit-history/${id}`,
                                    }}
                                    className="flex items-center justify-center"
                                  >
                                    <EyeIcon className=" text-gray-600 w-6 hover:text-blue-600" />
                                  </Link>
                                )}
                                {role === "employee" ||
                                role === "consultant" ? (
                                  "---"
                                ) : (
                                  <Link
                                    to={{
                                      pathname: `/edit-leaves/${id}`,
                                      state: { data },
                                    }}
                                    state={data}
                                    className="flex items-center justify-center"
                                  >
                                    <PencilAltIcon className=" text-gray-600 w-6 hover:text-blue-600" />
                                  </Link>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ))}
                {activeTab === "leaveDetails" && (
            <div className="px-5 mt-5">
              <Pagination
                currentPage={+paramsObject.page_no}
                lengthofItems={leaveInfo.total_count || 0}
                limit={paramsObject.limit}
                onPageChange={handlePageClick}
              />
            </div>)}
          </div>
        )}
        <Modal
          open={isOpenDocumentModal}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
        >
          <div className="w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]">
            <div className="document-wrapper px-4 divide-y">
              <div className="document-head py-4">
                <div className="flex items-center justify-between">
                  <h3 className=" text-gray-700 text-3xl font-semibold">
                    Documents
                  </h3>
                  <div
                    className=" w-6 h-6 cursor-pointer"
                    onClick={() => setIsOpenDocumentModal(false)}
                  >
                    <XIcon className=" text-black w-full h-full" />
                  </div>
                </div>
              </div>
              <div className="document-body py-4 max-h-96 overflow-auto">
                {singleLeaveData?.file_list?.length <= 0 ? (
                  <div className="text-center my-4">
                    <p className="text-gray-700 text-2xl">
                      No Document To Show
                    </p>
                  </div>
                ) : (
                  <div className="grid grid-cols-4 gap-4">
                    {singleLeaveData?.file_list?.map((data, idx) => {
                      const { file_name, document_media_type } = data;
                      return (
                        <>
                          {document_media_type === "pdf" ? (
                            <a
                              href={file_name}
                              target="_blank"
                              rel="noopener noreferrer"
                              className=" text-center py-2 px-2 rounded bg-gray-400 text-gray-700"
                            >
                              View PDF
                            </a>
                          ) : (
                            <ZoomImage key={idx} className="h-full">
                              <img
                                src={file_name}
                                alt="dummy"
                                className="border-2 border-black w-full h-full"
                              />
                            </ZoomImage>
                          )}
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="document-foot space-x-4 py-4">
                <button
                  className=" py-2 px-4 rounded font-semibold border border-[color:var(--color1)]"
                  onClick={() => setIsOpenDocumentModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {activeTab === "newLeave" && (
          <NewLeaveDetail key={year} year={year} id={id} />
        )}
        {activeTab === "leavemanagement" && (
          <LeaveManagement key={year} year={year} id={id} />
        )}
        {/* <NewLeaveDetail id={id} /> */}
      </div>
    </>
  );
}

export default LeaveDetail;
